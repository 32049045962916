import { useSelector, useDispatch } from "react-redux";
import { setCollection } from "Redux/nav/actions";
import { useState } from "react";

// -------------------------------------------------------------
// -------------------------------------------------------------
const CollectionsProcessFrame = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div className="columns">
        <div className="column">
          <i className={`fas fa-chevron-right ${isOpen ? "fa-rotate-90" : ""}`} onClick={() => setIsOpen(!isOpen)} />
          {isOpen && <CollectionsBar />}
        </div>
        <div className="column is-full">{children}</div>
      </div>
    </div>
  );
};

export default CollectionsProcessFrame;

// -------------------------------------------------------------
// -------------------------------------------------------------
const CollectionsBar = () => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const {
    project: { collectionsRepo, flatFilesReverse },
  } = projects;

  const { collectionid } = useSelector((state) => state.nav);

  const collections = Object.values(collectionsRepo).filter(
    (collection) => collection.fileids.filter(x => flatFilesReverse[x].ext === "CSV").length > 0
  );

  return (
    <aside className="menu" style={{ backgroundColor: "#fafafa" }}>
      <ul className="menu-list">
        {collections.map((collection) => {
          return (
            <li
              key={collection.id}
              onClick={() => {
                dispatch(
                  setCollection({
                    id: collection.id,
                    headline: collection.headline,
                  })
                );
              }}
            >
              <a className={collectionid === collection.id ? "is-active" : ""}>
                {collection.headline.replace(/ /g, "\u00A0")}
              </a>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};
