import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import { initCanonicals } from "Redux/database/actions";
// ------------------------------------------------------
import "./css.css";
import waitbar from "Media/waitbar.gif";
// ------------------------------------------------------
import { canonicalHeaders } from "Data/updateHeaders";
// -------------------------------------------------------------
import CollectionsProcessFrame from "Components/CollectionsProcessFrame";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Exported = () => {
  return (
    <CollectionsProcessFrame>
      <Index />
    </CollectionsProcessFrame>
  );
};

export default Exported;

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();

  const [hover, setHover] = useState(false);
  const projects = useSelector((state) => state.projects);
  const nav = useSelector((state) => state.nav);

  const { project } = projects;
  const { collectionsRepo, flatFilesReverse } = project;
  const { collectionid } = nav;
  const collection = collectionsRepo[collectionid];
  const { sql, hasTable } = collection;

  useEffect(() => {
    if (!sql) {
      refreshHeaders();
    }
  }, []);

  const refreshHeaders = () => {
    if (collectionid) {
      const files = collection.fileids.map(
        (fileid) => flatFilesReverse[fileid]
      );
      dispatch(initCanonicals(files, collectionid));
    }
  };

  if (!collectionid) {
    return null;
  }

  if (!sql) {
    return (
      <div className="box">
        <img src={waitbar} />
        <div>Gathering Headers</div>
      </div>
    );
  }

  const mapper = sql.mapper || {};
  const assignedCanonicals = Object.keys(mapper);

  const freeCanonicals = canonicalHeaders.filter(
    (x) => !assignedCanonicals.includes(x.header)
  );

  //const unassignedColumns = sql.dbHeaders.filter((x) => x.canonical === null);
  const unassignedColumns = sql.dbHeaders;
  //const masterColumns = sql.dbHeaders.filter((x) => x.canonical !== null);

  return (
    <div className="box">
      <div className="columns">
        {hasTable ? null : (
          <>
            <div className={`canonicals-hover ${hover ? "hover" : ""}`}>
              <button
                className="delete right"
                onClick={() => {
                  setHover(false);
                }}
              />
              {freeCanonicals.map((header) => (
                <Canonical header={header} key={header.header} />
              ))}
              <hr />
              {sql.secondaryTitle ? null : (
                <Secondary header={{ header: "Secondary Title" }} />
              )}
              {sql.secondaryRoyalty ? null : (
                <Secondary header={{ header: "Secondary Royalty" }} />
              )}
            </div>
            <div
              className={`canonicals-tab ${hover ? "hover" : ""}`}
              onClick={() => {
                setHover(true);
              }}
            >
              <i className="fa-duotone fa-dove" />
            </div>
            <div className="column" style = {{marginLeft: 80}}>
              Collection Columns&nbsp;
              <button className = "button is-small is-outlined" onClick = {refreshHeaders}>
                <span className = "icon is-small"><i className = "fa fa-refresh" />
                </span>
              </button>
              <hr />
              <div className="table-container headers">
                <table className="table is-striped is-bordered is-fullwidth">
                  {unassignedColumns.map((header) => (
                    <DBHeader key={header.header} header={header} />
                  ))}
                </table>
              </div>
            </div>
          </>
        )}

        <div className="column">
          <b>Master Columns</b>
          <hr />
          <div className="table-container">
            <table className="table is-striped is-bordered">
              <tbody>
                {assignedCanonicals.map(canonical => (
                  <MasterColumn
                    canonical = {canonical}
                    header={mapper[canonical]}
                    collectionid={collectionid}
                    key={canonical}
                    hasTable={hasTable}
                  />
                ))}
              </tbody>
            </table>
            <table className="table is-bordered">
              {sql.secondaryTitle ? (
                <tr>
                  <td>Secondary Title:</td>
                  <td>
                    <div className="tag is-info is-light is-small">
                      {sql.secondaryTitle}
                    </div>
                  </td>
                  <td>
                    <button
                      className="delete"
                      onClick={() => {
                        dispatch({
                          type: "ASSIGN_SECONDARY_TITLE",
                          collectionid,
                          header: null,
                        });
                      }}
                    />
                  </td>
                </tr>
              ) : null}

              {sql.secondaryRoyalty ? (
                <tr>
                  <td>Secondary Royalty:</td>
                  <td>
                    <div className="tag is-info is-light is-small">
                      {sql.secondaryRoyalty}
                    </div>
                  </td>
                  <td>
                    <button
                      className="delete"
                      onClick={() => {
                        dispatch({
                          type: "ASSIGN_SECONDARY_ROYALTY",
                          collectionid,
                          header: null,
                        });
                      }}
                    />
                  </td>
                </tr>
              ) : null}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

// ------------------------------------------------------
// ------------------------------------------------------
const MasterColumn = ({ collectionid, canonical, header, hasTable }) => {
  const dispatch = useDispatch();

  return (
    <tr>
      <td>
        <div className="tag is-success is-light is-small">
          {canonical}
        </div>
      </td>
      <td>
        <div className="tag is-info is-light is-small">{header}</div>
      </td>
      <td>
        {hasTable ? null : (
          <button
            className="delete"
            onClick={() => {
              dispatch({ type: "REMOVE_CANONICAL", collectionid, canonical });
            }}
          />
        )}
      </td>
    </tr>
  );
};
// ------------------------------------------------------
// ------------------------------------------------------
const Canonical = ({ header }) => {
  const onDragStart = (e) => {
    e.dataTransfer.setData("canonical", JSON.stringify(header));
  };

  return (
    <tr>
      <td>
        <div
          key={header.header}
          className="tag is-large is-success is-light canonical-header"
          draggable
          onDragStart={onDragStart}
        >
          <span>{header.header}</span>
          <span className="icon is-small">
            <i className="fa-duotone fa-dove"></i>
          </span>
        </div>
      </td>
    </tr>
  );
};

// ------------------------------------------------------
// ------------------------------------------------------
const Secondary = ({ header }) => {
  const onDragStart = (e) => {
    e.dataTransfer.setData("canonical", JSON.stringify(header));
  };

  return (
    <tr>
      <td>
        <div
          key={header.header}
          className="tag is-large is-success is-light canonical-header"
          draggable
          onDragStart={onDragStart}
        >
          <span>{header.header}</span>
          <span className="icon is-small">
            <i className="fa-duotone fa-dove"></i>
          </span>
        </div>
      </td>
    </tr>
  );
};

// ------------------------------------------------------
// ------------------------------------------------------
const DBHeader = ({ header }) => {
  const [disp, setDisp] = useState(false);

  const nav = useSelector((state) => state.nav);
  const { collectionid } = nav;


  const dispatch = useDispatch();

  const onDrop = (e) => {
    const canonical = JSON.parse(e.dataTransfer.getData("canonical"));
    console.log(canonical);
    if (canonical.header === "Secondary Royalty") {
      dispatch({
        type: "ASSIGN_SECONDARY_ROYALTY",
        collectionid,
        header,
      });
      return;
    }
    if (canonical.header === "Secondary Title") {
      dispatch({
        type: "ASSIGN_SECONDARY_TITLE",
        collectionid,
        header,
      });
      return;
    }
    dispatch({
      type: "ASSIGN_CANONICAL",
      collectionid,
      canonical,
      header,
    });
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <tr onDrop={onDrop} onDragOver={onDragOver}>
      <td>
        <div className="tag is-info is-light">
          {header.header.replace(/ /, "\u00A0")}
        </div>
      </td>
      <td>
        <div className="header-sample">
          <ul>
            {header.sample
              ? header.sample.map((sample, sampleid) => (
                  <li key={`sample-${sampleid}`}>
                    {sample && typeof sample === "string"
                      ? sample.replace(/ /, "\u00A0")
                      : sample}
                  </li>
                ))
              : null}
          </ul>
        </div>
      </td>
    </tr>
  );
};
