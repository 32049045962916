const initialTabs = [
  {
    tab: "checkin",
    headline: "Check-In",
    iconclass: "fa-duotone fa-sign-in-alt",
    active: true,
  },
  {
    tab: "collections",
    headline: "Collections",
    iconclass: "fa-duotone fa-box",
    tabs: [
      {
        tab: "define",
        headline: "Define Collections",
        iconclass: "fa-duotone fa-box-open",
        active: true,
      },
      {
        tab: "process",
        headline: "Process",
        iconclass: "fa-duotone fa-align-justify",
        tabs: [
          {
            tab: "files",
            headline: "Files",
            iconclass: "fa-duotone fa-file",
            active: true,
          },
          {
            tab: "stats",
            headline: "Stats",
            iconclass: "fa-duotone fa-plus-square",
          },
          {
            tab: "dates",
            headline: "Event Dates",
            iconclass: "fa-duotone fa-calendar",
          },
          {
            tab: "parsers",
            headline: "Parsers",
            iconclass: "fa-duotone fa-calculator",
            tabs: [
              {
                tab: "preset",
                headline: "Preset",
                iconclass: "fa-duotone fa-gift",
                active: true,
              },
              {
                tab: "mapping",
                headline: "Mappings",
                iconclass: "fa-duotone fa-map",
              },
              {
                tab: "custom",
                headline: "Custom",
                iconclass: "fa-duotone fa-code",
              },
            ]
          },
          { tab: "update", headline: "Update Table", iconclass: "fa-duotone fa-info" },
          {
            tab: "canonicals",
            headline: "Canonicals",
            iconclass: "fa-duotone fa-route",
          },
          { tab: "frame", headline: "Frame", iconclass: "fa-duotone fa-square" },
          { tab: "sql", headline: "SQL", iconclass: "fa-duotone fa-database" },
          {
            tab: "signature",
            headline: "Signature",
            iconclass: "fa-duotone fa-signature",
          },
        ],
        invisible: true,
      },
    ],
  },
  {
    tab: "merge",
    headline: "Merge Frames",
    iconclass: "fa-duotone fa-object-group",
  },
  {
    tab: "checkout",
    headline: "Checkout to Flyway",
    iconclass: "fa-duotone fa-code-branch",
  },
  {
    tab: "legacy",
    headline: "SQL Ops (Legacy)",
    iconclass: "fa-duotone fa-database",
    tabs: [
      {
        tab: "master",
        headline: "Master SQL",
        iconclass: "fa-duotone fa-database",
        active: true,
      },
      {
        tab: "norming",
        headline: "Norming",
        iconclass: "fa-duotone fa-align-justify",
        tabs: [
          {
            tab: "normSongs",
            headline: "Songs",
            iconclass: "fa-duotone fa-music",
            active: true,
          },
          {
            tab: "normIncome",
            headline: "Income Type",
            iconclass: "fa-duotone fa-money-check-alt",
          },
          {
            tab: "normSource",
            headline: "Source",
            iconclass: "fa-duotone fa-code-branch",
          },
          {
            tab: "normCountry",
            headline: "Country",
            iconclass: "fa-duotone fa-globe-americas",
          },
          {
            tab: "normSourceConfig",
            headline: "Source Configuration",
            iconclass: "fab fa-spotify",
          },
          {
            tab: "normSourceChain",
            headline: "Source Chain",
            iconclass: "fa-duotone fa-link",
          },
        ],
      },
      {
        tab: "currencies",
        headline: "Currencies",
        iconclass: "fa-duotone fa-money-check-alt",
      },
      {
        tab: "ids",
        headline: "Song IDs",
        iconclass: "fa-duotone fa-id-card",
      },
      {
        tab: "results",
        headline: "Results",
        iconclass: "fa-duotone fa-chart-area",
        tabs: [
          {
            tab: "summary",
            headline: "Summary Sheet",
            iconclass: "fa-duotone fa-file-invoice",
            active: true,
          },
          {
            tab: "downloads",
            headline: "Output Downloads",
            iconclass: "fa-duotone fa-download",
          },
        ],
      }
    ]
  }
];

// -------------------------------------------------------
// -------------------------------------------------------
const getPath = (tabs, ancestor, path) => {
  const newTabs = JSON.parse(JSON.stringify(tabs));
  for (var ii = 0; ii < newTabs.length; ii++) {
    const thisTab = newTabs[ii];
    if (thisTab.active) {
      const hasSubTabs = thisTab.hasOwnProperty("tabs");
      path.push({
        ancestor,
        tab: thisTab.tab,
        headline: thisTab.headline,
        hasSubTabs,
      });
      if (hasSubTabs) {
        getPath(thisTab.tabs, thisTab.tab, path);
      }
    }
  }
  return path;
};

// -------------------------------------------------------
// -------------------------------------------------------
/*const getLeaf = (tabs) => {
  const newTabs = JSON.parse(JSON.stringify(tabs));
  let leaf = null;
  for (var ii = 0; ii < newTabs.length; ii++) {
    const thisTab = newTabs[ii];
    if (thisTab.active) {
      leaf = thisTab.tabs ? getLeaf(thisTab.tabs) : thisTab.tab;
    }
  }
  return leaf;
};*/

// -------------------------------------------------------
// -------------------------------------------------------
const initialPath = getPath(initialTabs, "root", []);
const initialState = {
  tab: "banking",
  mainTab: "results",
  pickedTabs: ["collections", "norming"],
  mainTabs: initialTabs,
  path: initialPath,
  leaf: initialPath.at(-1).tab,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  let mainTabs;
  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    case "SET_COLLECTION":
      mainTabs = setProcessTab(
        [...state.mainTabs],
        action.collection.headline
      );
      return {
        ...state,
        collectionid: action.collection.id,
        mainTabs
      };

    // --------------------------------------------------------------
    case "SET_ACTIVE_TAB":
      mainTabs = setActive(
        action.current,
        action.ancestor,
        [...state.mainTabs],
        action.ancestor === "root"
      );
      const path = getPath(mainTabs, "root", []);
      const leaf = path.at(-1).tab;
      return {
        ...state,
        mainTabs,
        leaf,
        path,
      };
    // -------------------------------------------------------
    default:
      return state;
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
const setActive = (currentTab, ancestor, tabs, ancestral) => {
  const newTabs = JSON.parse(JSON.stringify(tabs));
  for (var ii = 0; ii < newTabs.length; ii++) {
    const thisTab = newTabs[ii];
    newTabs[ii] =
      thisTab.tab === currentTab
        ? { ...thisTab, active: true }
        : ancestral
        ? { ...thisTab, active: false }
        : thisTab;
    if (thisTab.tabs) {
      newTabs[ii].tabs = setActive(
        currentTab,
        ancestor,
        thisTab.tabs,
        thisTab.tab === ancestor
      );
    }
  }
  return newTabs;
};

// -------------------------------------------------------
// -------------------------------------------------------
const setProcessTab = (tabs, headline) => {
  const newTabs = JSON.parse(JSON.stringify(tabs));
  for (var ii = 0; ii < newTabs.length; ii++) {
    const thisTab = newTabs[ii];
    if(thisTab.tab==='process') {
      newTabs[ii].invisible = false;
      newTabs[ii].headline = headline;
    }
    if (thisTab.tabs) {
      newTabs[ii].tabs = setProcessTab(
        thisTab.tabs,
        headline
      );
    }
  }
  return newTabs;
};


export default reducer;
