import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const Checker = ({}) => {
    const columns = [
      "Catalogue_9LC",
      "Third_Party_9LC",
      "Statement_Period_9LC",
      "Distribution_Date_9LC",
      "Payout_Currency_9LC",
    ];
  
    const [status, setStatus] = useState(null);
    const [summary, setSummary] = useState(null);
  
    const projects = useSelector((state) => state.projects);
    const {
      project: { collectionsRepo, updateHeaders, flatFilesReverse },
    } = projects;
  
    const nav = useSelector((state) => state.nav);
    const { collectionid } = nav;
    const collection = collectionsRepo[collectionid];
    const { fileids } = collection;
  
    useEffect(() => {
      if (fileids && updateHeaders) {
        const data = fileids.map((fileid) => [
          ...flatFilesReverse[fileid].updateTable,
        ]);
        const reverseUpdateHeaders = {};
        updateHeaders.forEach((header, index) => {
          reverseUpdateHeaders[header] = index;
        });
  
        const thisStatus = {};
        let thisSummary = true;
        for (let ii = 0; ii < columns.length; ii++) {
          thisStatus[columns[ii]] = getStatus(
            data,
            reverseUpdateHeaders[columns[ii]]
          );
          thisSummary = thisSummary && thisStatus[columns[ii]];
        }
        setStatus(thisStatus);
        setSummary(thisSummary);
      }
    }, [fileids, updateHeaders]);
  
    if (!collectionid) {
      return null;
    }
  
    if (!status) return null;
  
    return (
      <div className="box" style={{ marginRight: 50 }}>
        <div style={{ margin: "0 4px 8px 4px" }}>
          <div
            className={`tag is-large is-info is-light ${
              summary ? "is-success" : "is-danger"
            }`}
          >
            {summary ? "All set" : "Data is incomplete"}
          </div>{" "}
        </div>
        <table className="table">
          <thead>
            <tr>
              {columns.map((column) => {
                return <th key={column}>{column}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {columns.map((column) => {
                return (
                  <td key={`column-${column}`}>
                    <i
                      className={`fa-duotone ${
                        status[column] ? "fa-check" : "fa-times"
                      }`}
                      style={{ color: status[column] ? "#48c774" : "#f14668" }}
                    />
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
export default Checker;

// ------------------------------------------------------------
// ------------------------------------------------------------
const getStatus = (data, columnIndex) => {
    let status = true;
    for (let ii = 0; ii < data.length; ii++) {
      if (data[ii][columnIndex] === null || data[ii][columnIndex] === "")
        status = false;
    }
    return status;
  };