import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import CollectionsProcessFrame from "Components/CollectionsProcessFrame";
import MultipleHeaders from "Components/MultipleHeaders/";
// -------------------------------------------------------------
import {
  getHeaders,
  eraseFile,
  dispCSV,
  dispPDF,
  getHeadersSet,
  cleanFile,
} from "Redux/files/actions";
import {
  removeCollection,
  setBulkCut,
  downloadCollection,
  removeEmpty,
  deEuropize,
  removeZeros,
  deFrac,
} from "Redux/collections/actions";
// -------------------------------------------------------------
import { explicitSize } from "Aux/";
import "./css.css";

const iClasses = {
  PDF: "fa-duotone fa-file-pdf filepic pdf",
  CSV: "fa-duotone fa-file-alt filepic csv",
  XLS: "fa-duotone fa-file-excel filepic xls",
  XLSX: "fa-duotone fa-file-excel filepic xls",
  ZIP: "fa-duotone fa-file-archive filepic zip",
};
// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();

  const [isAll, setIsAll] = useState(false);
  const [ids, setIds] = useState([]);

  const [state, setState] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [deeuropize, setDeeuropize] = useState(false);
  const [defrac, setDefrac] = useState(false);
  const [removingZeros, setRemovingZeros] = useState(false);

  const projects = useSelector((state) => state.projects);
  const {
    project: { collectionsRepo, flatFilesReverse },
  } = projects;
  const nav = useSelector((state) => state.nav);
  const { collectionid } = nav;

  useEffect(() => {
    setIds(isAll ? collection.fileids : []);
  }, [isAll]);

  const eraseFiles = () => {
    if (window.confirm("Are you sure you want to delete these files?")) {
      for (const fileid of ids) {
        dispatch(eraseFile(fileid, collectionid));
      }
    }
  };

  if (!collectionid) {
    return null;
  }

  const clickedFile = (file) => {
    switch (file.ext) {
      case "CSV":
        dispatch(dispCSV(file));
        break;
      case "PDF":
        dispatch(dispPDF(file));
        break;
      default:
    }
  };

  const collection = collectionsRepo[collectionid];
  const files = collection.fileids.map((fileid) => flatFilesReverse[fileid]);

  const cleanFiles = async () => {
    dispatch({
      type: "INIT_MAIN_PROGRESS",
      title: "Cleaning special characters",
      total: files.length,
    });
    for (var ii = 0; ii < files.length; ii++) {
      dispatch({
        type: "UPDATE_MAIN_PROGRESS",
        current: ii,
        status: files[ii].name,
      });
      await dispatch(cleanFile(files[ii]));
    }
    dispatch({
      type: "CLOSE_MAIN_PROGRESS",
    });
  };

  let hasWarning = false;
  for (const file of files) {
    if (explicitSize(file.size, 10).unit === "B") {
      hasWarning = true;
    }
  }

  return (
    <CollectionsProcessFrame>
      {hasWarning && (
        <div
          className="notification is-warning is-light"
          style={{ marginRight: 100 }}
        >
          There are files with size less than a KB in this collection.
        </div>
      )}
      <MultipleHeaders />
      <div className="buttons has-addons">
        <button
          className="button is-small box-button"
          onClick={() => {
            dispatch(getHeaders(files));
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-heading" />
          </span>
          <span>View Headers</span>
        </button>

        <button
          className={`button box-button is-small ${
            removingZeros ? "is-loading" : ""
          }`}
          onClick={(e) => {
            setRemovingZeros(true);
            e.stopPropagation();
            dispatch(
              removeZeros(files, () => {
                setRemovingZeros(false);
              })
            );
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-00" />
          </span>
          <span>Strip Zeros</span>
        </button>

        <button
          className={`button box-button is-small ${
            removing ? "is-loading" : ""
          }`}
          onClick={(e) => {
            setRemoving(true);
            e.stopPropagation();
            dispatch(
              removeEmpty(files, () => {
                setRemoving(false);
              })
            );
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-align-left" />
          </span>
          <span>Remove Empty</span>
        </button>

        <button
          className={`button box-button is-small ${defrac ? "is-loading" : ""}`}
          onClick={(e) => {
            setDefrac(true);
            e.stopPropagation();
            dispatch(
              deFrac(files, () => {
                setDefrac(false);
              })
            );
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-percent" />
          </span>
          <span>Convert Fractions</span>
        </button>

        <button
          className={`button box-button is-small ${
            deeuropize ? "is-loading" : ""
          }`}
          onClick={(e) => {
            setDeeuropize(true);
            e.stopPropagation();
            dispatch(
              deEuropize(files, () => {
                setDeeuropize(false);
              })
            );
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-flag-usa" />
          </span>
          <span>US Decimals</span>
        </button>

        <button
          className="button box-button is-small"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setBulkCut({ collection }));
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-cut" />
          </span>
          <span>Bulk Cut</span>
        </button>

        <button
          className="button box-button is-small"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(getHeadersSet(collection, files));
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-columns" />
          </span>
          <span>Bulk Reference</span>
        </button>

        <button
          className="button box-button is-small"
          onClick={(evt) => {
            evt.stopPropagation();
            dispatch(removeCollection(collection.id));
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-trash-alt" />
          </span>
          <span>Remove Collection</span>
        </button>

        <button
          className="button box-button is-small"
          onClick={(evt) => {
            evt.stopPropagation();
            dispatch(
              downloadCollection(files, () => {
                console.log("Downloaded");
              })
            );
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-download" />
          </span>
          <span>Download</span>
        </button>

        <button
          className="button is-small box-button"
          onClick={(evt) => {
            evt.stopPropagation();
            cleanFiles();
          }}
        >
          <span className="icon is-small">
            <i className="fa-duotone fa-broom" />
          </span>
          <span>Clean Special Chars</span>
        </button>
      </div>
      <table className="table is-striped is-hoverable small-table is-bordered collection">
        <thead>
          <tr>
            <th>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={isAll}
                  onChange={() => setIsAll(!isAll)}
                />
                <div
                  className="is-clickable"
                  style={{ margin: "0 0 0 17px", minWidth: 35 }}
                  onClick={ids.length > 0 ? eraseFiles : undefined}
                >
                  {ids.length > 0 ? (
                    <i className="fa-duotone fa-trash-can" />
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>
              </div>
            </th>
            <th>Type</th>
            <th>File</th>
            <th>Size</th>
            <th>Ref</th>
            <th>Cut</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, fileindex) => {
            const eS = explicitSize(file.size, 10);
            return (
              <tr
                key={`${fileindex}-${file.name}`}
                className={`file-row`}
                onClick={() => clickedFile(file)}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={ids.includes(file.id)}
                    onClick={(evt) => {
                      evt.stopPropagation();
                    }}
                    onChange={(evt) => {
                      setIds(
                        ids.includes(file.id)
                          ? ids.filter((id) => id !== file.id)
                          : [...ids, file.id]
                      );
                    }}
                  />
                </td>
                <td>
                  <i className={iClasses[file.ext]} />
                </td>
                <td>{file.name}</td>
                <td className={`${eS.unit === "B" ? "is-warning" : ""}`}>
                  <div className="file-size">
                    {eS.size}&nbsp;{eS.unit}
                  </div>
                </td>
                <td>
                  {file.ref ? (
                    <div className="tag is-light is-primary">{file.ref}</div>
                  ) : null}
                </td>
                <td>{file.cut}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </CollectionsProcessFrame>
  );
};
export default Index;
