import React from "react";
import { useDispatch } from "react-redux";
import { dispCSV, dispPDF, eraseFile } from "Redux/files/actions";
import { explicitSize } from "Aux";
// -------------------------------------------------------
import "./css.css";

// -------------------------------------------------------
// -------------------------------------------------------
const File = ({ file, collectionid }) => {
  const dispatch = useDispatch();

  const clickedFile = () => {
    console.log("clickedFile", file.ext);
    switch (file.ext) {
      case "CSV":
        dispatch(dispCSV(file));
        break;
      case "PDF":
        dispatch(dispPDF(file));
        break;
      default:
    }
  };

  const iClasses = {
    PDF: "fa-duotone fa-file-pdf filepic pdf",
    CSV: "fa-duotone fa-file-alt filepic csv",
    XLS: "fa-duotone fa-file-excel filepic xls",
    XLSX: "fa-duotone fa-file-excel filepic xls",
    ZIP: "fa-duotone fa-file-archive filepic zip",
  };

  if(!file) return null;
  const iClass = iClasses[file.ext] || "fa-duotone fa-check filepic csv";
  const eS = explicitSize(file.size, 10);
  return (
    <li className="file-entry" onClick={clickedFile}>
      <i className={iClass}  />
      <div className="file-file">
        {file.name}
        <div className="file-size">
          {eS.size}&nbsp;{eS.unit}
        </div>
        {file.ref ? <div className="file-ref">{file.ref}</div> : null}
        {file.cut ? <div className="file-cut">{file.cut}</div> : null}

        {false && (file.duplicates
          ? file.duplicates.length
            ? file.duplicates.map((x, xind) => (
                <div className="file-duplicate" key={x}>
                  Dup
                </div>
              ))
            : null
          : null)}
      </div>
      {collectionid ? (
        <button
          className="delete"
          onClick={(evt) => {
            evt.stopPropagation();
            dispatch(eraseFile(file.id, collectionid));
          }}
        />
      ) : null}
    </li>
  );
};

export default File;
