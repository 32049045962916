const initialState = {
  project: null,
  newProject: false,
  list: null,
  unsaved: false,
  nFiles: null,
  nCSV: null,
  showMore: null,
  pivotPopup: null,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  let stats;
  let collectionsRepo;
  let flatFiles;
  let flatFilesReverse;
  let fileids;
  let sourceData, songData;
  let sql;
  let dateparsers;
  let mapper;
  let parsers;
  let steps;

  switch (action.type) {
    // --------------------------------------------------------------
    case "ADD_PARSER":
      collectionsRepo = { ...state.project.collectionsRepo };
      parsers = collectionsRepo[action.collectionid].hasOwnProperty("parsers")
        ? [...collectionsRepo[action.collectionid].parsers]
        : [];
      const steps = action.steps ? action.steps : [];
      parsers.push({
        headline: action.headline,
        datatype: action.datatype,
        mapping: action.mapping,
        mappingHeadline: action.mappingHeadline,
        steps,
      });

      collectionsRepo[action.collectionid].parsers = parsers;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // --------------------------------------------------------------
    case "SET_PRESET":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].preset = action.preset;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };

    // --------------------------------------------------------------
    case "REMOVE_PARSER":
      collectionsRepo = { ...state.project.collectionsRepo };
      parsers = collectionsRepo[action.collectionid].hasOwnProperty("parsers")
        ? [...collectionsRepo[action.collectionid].parsers]
        : [];

      collectionsRepo[action.collectionid].parsers = parsers.filter(
        (parser, parserindex) => parserindex !== action.parserindex
      );
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };

    // --------------------------------------------------------------
    case "ADD_PARSER_STEP":
      collectionsRepo = { ...state.project.collectionsRepo };
      if (!collectionsRepo[action.collectionid].hasOwnProperty("parsers")) {
        return state;
      }

      parsers = collectionsRepo[action.collectionid].hasOwnProperty("parsers")
        ? [...collectionsRepo[action.collectionid].parsers]
        : [];

      collectionsRepo[action.collectionid].parsers = parsers.map(
        (parser, parserindex) => {
          if (parserindex !== action.parserindex) return parser;
          return {
            ...parser,
            steps: [
              ...parser.steps,
              {
                type: action.steptype,
                fields: [],
              },
            ],
          };
        }
      );

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };

    // --------------------------------------------------------------
    case "REMOVE_PARSER_STEP":
      collectionsRepo = { ...state.project.collectionsRepo };
      if (!collectionsRepo[action.collectionid].hasOwnProperty("parsers")) {
        return state;
      }

      parsers = collectionsRepo[action.collectionid].hasOwnProperty("parsers")
        ? [...collectionsRepo[action.collectionid].parsers]
        : [];

      collectionsRepo[action.collectionid].parsers = parsers.map(
        (parser, parserindex) => {
          if (parserindex !== action.parserindex) return parser;
          return {
            ...parser,
            steps: [...parser.steps].filter(
              (step, stepindex) => stepindex !== action.stepindex
            ),
          };
        }
      );

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };

    // --------------------------------------------------------------
    case "ADD_FIELD_TO_PARSER_STEP":
      collectionsRepo = { ...state.project.collectionsRepo };
      if (!collectionsRepo[action.collectionid].hasOwnProperty("parsers")) {
        return state;
      }

      parsers = collectionsRepo[action.collectionid].hasOwnProperty("parsers")
        ? [...collectionsRepo[action.collectionid].parsers]
        : [];

      parsers[action.parserindex].steps = parsers[action.parserindex].steps.map(
        (step, stepindex) => {
          if (stepindex !== action.stepindex) return step;
          return {
            ...step,
            fields: [...step.fields, action.column],
          };
        }
      );
      collectionsRepo[action.collectionid].parsers = parsers;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };

    // --------------------------------------------------------------
    case "REMOVE_FIELD_FROM_PARSER_STEP":
      collectionsRepo = { ...state.project.collectionsRepo };
      if (!collectionsRepo[action.collectionid].hasOwnProperty("parsers")) {
        return state;
      }

      parsers = collectionsRepo[action.collectionid].hasOwnProperty("parsers")
        ? [...collectionsRepo[action.collectionid].parsers]
        : [];

      parsers[action.parserindex].steps = parsers[action.parserindex].steps.map(
        (step, stepindex) => {
          if (stepindex !== action.stepindex) return step;
          return {
            ...step,
            fields: [...step.fields].filter((x, i) => i !== action.itemindex),
          };
        }
      );
      collectionsRepo[action.collectionid].parsers = parsers;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };

    // -------------------------------------------------------
    case "UPDATE_PIVOT":
      return {
        ...state,
        unsaved: true,
        pivotPopup: false,
        project: {
          ...state.project,
          ...action.data,
        },
      };

    // -------------------------------------------------------
    case "SHOW_PIVOT_POPUP":
      return {
        ...state,
        pivotPopup: action.show,
      };

    // -------------------------------------------------------
    case "POPULATE_SONGS":
      console.log("Populating songs", action.songs);
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          songData: action.songs.map((x) => [
            x,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
          ]),
        },
      };

    // -------------------------------------------------------
    case "ATTACH_SQL_TO_COLLECTION":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].sql = action.sql;

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ASSIGN_CANONICAL__":
      collectionsRepo = { ...state.project.collectionsRepo };
      sql = collectionsRepo[action.collectionid].sql;
      sql = {
        ...sql,
        dbHeaders: sql.dbHeaders.map((x) =>
          x.header === action.header.header
            ? {
                ...x,
                canonical: action.canonical.header,
                datatype: action.canonical.datatype,
              }
            : x
        ),
      };
      collectionsRepo[action.collectionid].sql = sql;

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ASSIGN_CANONICAL":
      collectionsRepo = { ...state.project.collectionsRepo };
      sql = collectionsRepo[action.collectionid].sql;
      mapper = sql.mapper || {};
      sql = {
        ...sql,
        mapper: {
          ...mapper,
          [action.canonical.header]: action.header.header,
        },
      };
      collectionsRepo[action.collectionid].sql = sql;

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "REMOVE_CANONICAL":
      collectionsRepo = { ...state.project.collectionsRepo };
      sql = collectionsRepo[action.collectionid].sql;
      mapper = sql.mapper;
      delete mapper[action.canonical];
      sql = { ...sql, mapper };
      collectionsRepo[action.collectionid].sql = sql;

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ATTACH_EVENT_DATES":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].hasEventDates = action.hasDates;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ASSIGN_TERRITORY_VALUE":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].territory = action.territory;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ASSIGN_TERRITORY_COLUMN":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].territoryCol = action.column;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    /*case "ASSIGN_EVENT_DATE_COLUMN":
      collectionsRepo = { ...state.project.collectionsRepo };
      datecols = collectionsRepo[action.collectionid].datecols || [];
      datecols = [...datecols];
      if (!datecols.includes(action.column)) datecols.push(action.column);
      if (datecols.length > 2) return state;
      collectionsRepo[action.collectionid].datecols = datecols;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "REMOVE_EVENT_DATE_COLUMN":
      collectionsRepo = { ...state.project.collectionsRepo };
      datecols = collectionsRepo[action.collectionid].datecols || [];
      datecols = [...datecols];
      datecols = datecols.filter((x) => x !== action.column);
      collectionsRepo[action.collectionid].datecols = datecols;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };*/
    // -------------------------------------------------------
    case "ASSIGN_EVENT_DATE_PARSER":
      collectionsRepo = { ...state.project.collectionsRepo };
      dateparsers = collectionsRepo[action.collectionid].dateparsers || [];
      dateparsers = [...dateparsers];
      dateparsers.push({ column: action.column, format: "" });
      if (dateparsers.length > 2) return state;
      collectionsRepo[action.collectionid].dateparsers = dateparsers;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ASSIGN_EVENT_DATE_PARSER_FORMAT":
      collectionsRepo = { ...state.project.collectionsRepo };
      dateparsers = collectionsRepo[action.collectionid].dateparsers;
      dateparsers = [...dateparsers];
      dateparsers[action.parserindex].format = action.format;
      collectionsRepo[action.collectionid].dateparsers = dateparsers;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "REMOVE_EVENT_DATE_PARSER":
      collectionsRepo = { ...state.project.collectionsRepo };
      dateparsers = [...collectionsRepo[action.collectionid].dateparsers];
      dateparsers = dateparsers.filter((x, idx) => idx !== action.index);
      collectionsRepo[action.collectionid].dateparsers = dateparsers;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ASSIGN_SECONDARY_ROYALTY":
      collectionsRepo = { ...state.project.collectionsRepo };
      sql = collectionsRepo[action.collectionid].sql;
      sql = { ...sql, secondaryRoyalty: action.header.header };
      collectionsRepo[action.collectionid].sql = sql;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ASSIGN_SECONDARY_TITLE":
      collectionsRepo = { ...state.project.collectionsRepo };
      sql = collectionsRepo[action.collectionid].sql;
      sql = { ...sql, secondaryTitle: action.header.header };
      collectionsRepo[action.collectionid].sql = sql;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ASSIGN_PAYOR":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].payor = action.payor;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ATTACH_TABLE_TO_COLLECTION":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].hasTable = action.hasTable;

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "ATTACH_FRAME_TO_COLLECTION":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].hasFrame = action.hasFrame;
      collectionsRepo[action.collectionid].nLines = action.nLines;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "MERGE_TABLE_WITH_MASTER":
      if (state.project.tables.includes(action.collectionid)) {
        return { ...state };
      }
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          tables: [...state.project.tables, action.collectionid],
        },
      };
    // -------------------------------------------------------
    case "UNMERGE_TABLE_WITH_MASTER":
      if (!state.project.tables.includes(action.collectionid)) {
        return { ...state };
      }
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          tables: [...state.project.tables].filter(
            (x) => x !== action.collectionid
          ),
        },
      };
    // -------------------------------------------------------
    case "CLEAR_ALL_FRAMES":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          hasFrame: false,
          frames: [],
        },
      };

    // -------------------------------------------------------
    case "SET_PROJECT_HAS_FRAME":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          hasFrame: action.hasFrame,
        },
      };

    // -------------------------------------------------------
    case "MERGE_FRAME":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          hasFrame: true,
          frames: [...state.project.frames, action.collectionid],
        },
      };
    // -------------------------------------------------------
    case "UNMERGE_FRAME":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          frames: [...state.project.frames].filter(
            (x) => x !== action.collectionid
          ),
        },
      };
    // -------------------------------------------------------
    case "ASSIGN_TO_FLYWAY":
      if (state.project.flywayTables.includes(action.collectionid)) {
        return { ...state };
      }
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          hasFrame: false,
          flywayTables: [...state.project.flywayTables, action.collectionid],
        },
      };
    // -------------------------------------------------------
    case "UNASSIGN_TO_FLYWAY":
      if (!state.project.flywayTables.includes(action.collectionid)) {
        return { ...state };
      }
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          hasFrame: false,
          flywayTables: [...state.project.flywayTables].filter(
            (x) => x !== action.collectionid
          ),
        },
      };
    // -------------------------------------------------------
    case "FRAME_BUILT":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          hasFrame: true,
        },
      };
    // -------------------------------------------------------
    case "TRUNCATE_MASTER":
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, tables: [] },
      };
    // -------------------------------------------------------
    case "SET_MASTER_TABLE":
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, masterTable: action.masterTable },
      };
    // -------------------------------------------------------
    case "INIT_MASTER_TABLE":
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, hasMaster: true, tables: [] },
      };

    // -------------------------------------------------------
    case "ADD_SOURCE_ROW":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          sourceData: [...state.project.sourceData, ["", ""]],
        },
      };

    // -------------------------------------------------------
    case "SUBTRACT_SOURCE_ROW":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          sourceData: [...state.project.sourceData].slice(
            0,
            state.project.sourceData.length - 1
          ),
        },
      };

    // -------------------------------------------------------
    case "ADD_SONG_ROW":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          songData: [
            ...state.project.songData,
            ["", "", "", "", "", "", "", "", "", "", ""],
          ],
        },
      };

    // -------------------------------------------------------
    case "SUBTRACT_SONG_ROW":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          songData: [...state.project.songData].slice(
            0,
            state.project.songData.length - 1
          ),
        },
      };

    // -------------------------------------------------------
    case "CLEAR_SOURCE_TABLE":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          sourceData: [["", ""]],
        },
      };
    // -------------------------------------------------------
    case "CLEAR_SONG_TABLE":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          songData: [["", "", "", "", "", "", "", "", "", "", ""]],
        },
      };

    // -------------------------------------------------------
    case "UPDATE_SOURCE_TABLE":
      sourceData = [...state.project.sourceData];
      if (sourceData[action.row] === undefined) {
        sourceData[action.row] = ["", ""];
      }
      sourceData[action.row][action.col] = action.value;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, sourceData },
      };
    // -------------------------------------------------------
    case "UPDATE_SONG_TABLE":
      songData = [...state.project.songData];
      if (songData[action.row] === undefined) {
        songData[action.row] = ["", "", "", "", "", "", "", "", "", "", ""];
      }
      songData[action.row][action.col] = action.value;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, songData },
      };
    // -------------------------------------------------------
    case "UPLOAD_SOURCE_TABLE":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].sourceData = action.table;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "UPLOAD_SONG_TABLE":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].songData = action.table;
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "SET_SOURCE_LINK":
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, sourceLink: action.sourceLink },
      };
    // -------------------------------------------------------
    case "SET_SONG_LINK":
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, songLink: action.songLink },
      };
    // -------------------------------------------------------
    case "CHANGES_SAVED":
      return { ...state, unsaved: false };
    // -------------------------------------------------------
    case "UTF8_ENCODED":
      return { ...state, project: { ...state.project, utf8: true } };

    // -------------------------------------------------------
    case "ADD_FILES_TO_COLLECTION":
      fileids = new Set(
        state.project.collectionsRepo[action.collectionid].fileids
      );
      action.fileids.forEach((fileid) => fileids.add(fileid));
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          collectionsRepo: {
            ...state.project.collectionsRepo,
            [action.collectionid]: {
              ...state.project.collectionsRepo[action.collectionid],
              fileids: Array.from(fileids).sort(),
            },
          },
        },
      };

    // -------------------------------------------------------
    case "NEW_COLLECTION":
      return {
        ...state,
        project: {
          ...state.project,
          collectionids: [...state.project.collectionids, action.collection.id],
          collectionsRepo: {
            ...state.project.collectionsRepo,
            [action.collection.id]: action.collection,
          },
        },
        unsaved: true,
      };

    // -------------------------------------------------------
    case "REMOVE_COLLECTION":
      collectionsRepo = { ...state.project.collectionsRepo };
      delete collectionsRepo[action.collectionid];
      Object.keys(collectionsRepo).forEach((collectionid) => {
        collectionsRepo[collectionid] = {
          ...collectionsRepo[collectionid],
          collectionids: [
            ...collectionsRepo[collectionid].collectionids,
          ].filter((x) => x !== action.collectionid),
        };
      });
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          collectionids: [...state.project.collectionids].filter(
            (x) => x !== action.collectionid
          ),
          collectionsRepo,
        },
      };

    // -------------------------------------------------------
    case "CLEAR_COLLECTION_FILES":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].fileids = [];
      collectionsRepo[action.collectionid].nFiles = action.nFiles;

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };
    // -------------------------------------------------------
    case "SIGNATURE_SET":
      console.log("SIGNATURE_SET");
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].sql = action.sql;
      collectionsRepo[action.collectionid].parsers = action.parsers;
      collectionsRepo[action.collectionid].payor = action.payor;

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };

    // -------------------------------------------------------
    case "ADD_PARTITION":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.partition.id] = { ...action.partition };
      collectionsRepo[action.collectionid].collectionids = [
        ...collectionsRepo[action.collectionid].collectionids,
        action.partition.id,
      ];
      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };

    // -------------------------------------------------------
    case "SET_PROJECT":
      stats = getFileStats(action.project.flatFiles);

      return {
        ...state,
        ...stats,
        project: action.project,
      };

    // -------------------------------------------------------
    case "ATTACH_DIR_TO_PROJECT":

      const attach_flatFilesReverse = { ...state.project.flatFilesReverse };
      action.flatFiles.forEach((file) => {
        attach_flatFilesReverse[file.id] = file;
      });

      return {
        ...state,
        ...stats,
        unsaved: true,
        project: {
          ...state.project,
          flatFilesReverse: attach_flatFilesReverse,
          dirs: [...state.project.dirs, action.dir],
        },
      };

    // -------------------------------------------------------
    case "INITIALIZE_PROJECT":
      stats = getFileStats(action.flatFiles);
      flatFilesReverse = {};
      action.flatFiles.forEach((file) => {
        flatFilesReverse[file.id] = file;
      });
      return {
        ...state,
        ...stats,
        project: {
          ...state.project,
          mainDir: action.dir,
          flatFiles: action.flatFiles,
          flatFilesReverse,
          updateHeaders: action.updateHeaders,
        },
      };

    // -------------------------------------------------------
    case "EMPTY_DUPLICATES":
      console.log("EMPTY_DUPLICATES");
      const flatFilesReverse = { ...state.project.flatFilesReverse };
      Object.keys(flatFilesReverse).forEach((fileid) => {
        delete flatFilesReverse[fileid].duplicates;
      });

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, flatFilesReverse },
      };

    // -------------------------------------------------------
    case "UPDATE_UPDATE_TABLE":
      let localFlatFiles = state.project.flatFiles;
      let localFlatFilesReverse = state.project.flatFilesReverse;
      
      const updateTable = [
        ...localFlatFilesReverse[action.fileid].updateTable,
      ];
      updateTable[action.col] = action.value;

      localFlatFiles = [...localFlatFiles].map((flatFile) => {
        if (flatFile.id !== action.fileid) return flatFile;
        return { ...flatFile, updateTable };
      });
      localFlatFilesReverse = { ...localFlatFilesReverse };
      localFlatFilesReverse[action.fileid].updateTable = updateTable;

      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          flatFiles: localFlatFiles,
          flatFilesReverse: localFlatFilesReverse,
        },
      };

    // -------------------------------------------------------
    case "UPDATE_FILE_CUT":
      const flatFilesCut = [...state.project.flatFiles].map((flatFile) => {
        if (flatFile.id !== action.fileid) return flatFile;
        return { ...flatFile, cut: action.cut };
      });
      const flatFilesReverseCut = { ...state.project.flatFilesReverse };
      flatFilesReverseCut[action.fileid].cut = action.cut;

      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          flatFiles: flatFilesCut,
          flatFilesReverse: flatFilesReverseCut,
        },
      };

    // -------------------------------------------------------
    case "APPLY_BULK_CUT":
      const apply_flatFilesReverse = { ...state.project.flatFilesReverse };
      for (var ii = 0; ii < action.fileids.length; ii++) {
        apply_flatFilesReverse[action.fileids[ii]].cut = action.cut;
      }
      const apply_flatFiles = [...state.project.flatFiles].map(
        (flatFile) => apply_flatFilesReverse[flatFile.id]
      );
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          flatFiles: apply_flatFiles,
          flatFilesReverse: apply_flatFilesReverse,
        },
      };
    // -------------------------------------------------------
    case "SET_BULK_REFERENCE":
      const bulkRef_flatFilesReverse = { ...state.project.flatFilesReverse };
      for (var ii = 0; ii < action.fileids.length; ii++) {
        bulkRef_flatFilesReverse[action.fileids[ii]].ref = action.ref;
      }
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          flatFilesReverse: bulkRef_flatFilesReverse,
        },
      };
    // -------------------------------------------------------
    case "UPDATE_FILE_REF":
      const fileRef_flatFiles = [...state.project.flatFiles].map((flatFile) => {
        if (flatFile.id !== action.fileid) return flatFile;
        return { ...flatFile, ref: action.ref };
      });
      const fileRef_flatFilesReverse = { ...state.project.flatFilesReverse };
      fileRef_flatFilesReverse[action.fileid].ref = action.ref;

      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          flatFiles: fileRef_flatFiles,
          flatFilesReverse: fileRef_flatFilesReverse,
        },
      };
    // -------------------------------------------------------
    case "SET_OUTPUT_CURRENCY":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          outputCurrency: action.outputCurrency,
        },
      };
    // -------------------------------------------------------
    case "IS_CURRENCY_ADJUSTED":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          currencyAdjusted: action.currencyAdjusted,
        },
      };

    // -------------------------------------------------------
    case "SET_FILE_ATTRIBUTE":
      localFlatFiles = [...state.project.flatFiles].map((flatFile) => {
        if (flatFile.id !== action.fileid) return flatFile;
        return {
          ...flatFile,
          attributes: {
            ...flatFile.attributes,
            [action.attribute]: {
              value: action.value,
              attribute: action.attribute,
              title: action.title,
            },
          },
        };
      });
      localFlatFilesReverse = { ...state.project.flatFilesReverse };
      localFlatFilesReverse[action.fileid].attributes[action.attribute] = {
        value: action.value,
        attribute: action.attribute,
        title: action.title,
      };
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          flatFiles: localFlatFiles,
          flatFilesReverse: localFlatFilesReverse,
        },
      };
    // -------------------------------------------------------
    case "NEW_PROJECT_HIDE":
      return {
        ...state,
        newProject: false,
      };
    // -------------------------------------------------------
    case "NEW_PROJECT_SHOW":
      return {
        ...state,
        newProject: true,
      };

    // -------------------------------------------------------
    case "NEW_PROJECT":
      return {
        ...state,
        list: [{ ...action.project }, ...state.list],
        newProject: false,
      };
    // -------------------------------------------------------
    case "SET_PROJECTS":
      if (action.projects === null) {
        return { ...state, list: null, showMore: false };
      }
      return {
        ...state,
        list: [...action.projects],
        showMore: action.showMore,
      };
    // -------------------------------------------------------
    case "ERASE_PROJECT":
      let list = [...state.list].filter((x) => x._id !== action.projectid);
      return {
        ...state,
        list,
      };

    // -------------------------------------------------------
    case "RESET_PROJECT":
      return {
        ...state,
        project: null,
      };

    // -------------------------------------------------------
    case "ERASE_FILE":
      collectionsRepo = { ...state.project.collectionsRepo };
      collectionsRepo[action.collectionid].fileids = [
        ...collectionsRepo[action.collectionid].fileids,
      ].filter((x) => x !== action.fileid);

      return {
        ...state,
        unsaved: true,
        project: { ...state.project, collectionsRepo },
      };

    // -------------------------------------------------------
    case "UPDATE_CURRENCY":
      return {
        ...state,
        unsaved: true,
        project: {
          ...state.project,
          currency: action.currency,
        },
      };
    // -------------------------------------------------------
    case "SET_UNSAVED":
      return {
        ...state,
        unsaved: true,
      };
    // -------------------------------------------------------
    default:
      return state;
  }
};
export default reducer;

// -------------------------------------------------------
// -------------------------------------------------------
const getFileStats = (flatFiles) => {
  return {
    nFiles: flatFiles.length,
    nCSV: flatFiles.filter((x) => x.ext === "CSV").length,
    nDAT: flatFiles.filter((x) => x.ext === "DAT").length,
    nTXT: flatFiles.filter((x) => x.ext === "TXT").length,
    nTAB: flatFiles.filter((x) => x.ext === "TAB").length,
    nXLSM: flatFiles.filter((x) => x.ext === "XLSM").length,
    nPDF: flatFiles.filter((x) => x.ext === "PDF").length,
    nXLS: flatFiles.filter((x) => x.ext === "XLS").length,
    nXLSX: flatFiles.filter((x) => x.ext === "XLSX").length,
    nZIP: flatFiles.filter((x) => x.ext === "ZIP").length,
  };
};

// -------------------------------------------------------
// -------------------------------------------------------
const eraseFileFromCollectionsTree = (collections, fileid, collectionid) => {
  return collections.map((collection) => {
    const subCollections = [...collection.collections];
    const fileids =
      collection.id !== collectionid
        ? [...collection.fileids]
        : [...collection.fileids].filter((x) => x !== fileid);

    return {
      ...collection,
      fileids,
      collections: eraseFileFromCollectionsTree(
        subCollections,
        fileid,
        collectionid
      ),
    };
  });
};
