import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { buildFrame, dropTable, queryDB } from "Redux/database/actions";
// -------------------------------------------------------------
import Checker from "Components/Checker";
import RunBuild from "./RunBuild";
import "./css.css";
// -------------------------------------------------------------
import CollectionsProcessFrame from "Components/CollectionsProcessFrame";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Exported = () => {
  return (
    <CollectionsProcessFrame>
      <Index />
    </CollectionsProcessFrame>
  );
};

export default Exported;

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const database = useSelector((state) => state.database);
  const nav = useSelector((state) => state.nav);

  const { project } = projects;
  const { collectionsRepo, updateHeaders } = project;
  const { collectionid } = nav;
  const collection = collectionsRepo[collectionid];

  if (!project.dirs || !collection) return null;
  const { sql, hasFrame, nLines } = collection;

  const thisDir = project.dirs[0];
  const headline = thisDir.title;
  const mainPath = thisDir.path.slice(0, -headline.length);
  const files = collection.fileids.map(
    (fileid) => project.flatFilesReverse[fileid]
  );

  const buildCollectionFrame = () => {
    const { secondaryRoyalty, secondaryTitle, mapper } = sql;

    dispatch(
      buildFrame(
        collection.id,
        project.database,
        files,
        mainPath,
        mapper,
        secondaryRoyalty,
        secondaryTitle,
        updateHeaders
      )
    );
    dispatch({ type: "SET_PROJECT_HAS_FRAME", hasFrame: false });
  };

  return (
    <>
      <Checker />
      {database.dbProgress ? <RunBuild /> : null}
      <div className="field has-addons">
        {hasFrame ? (
          <div>
            {
              nLines!==null && (
                <div>
                <div className="tag is-info is-light is-large">Collection Frame Built ({nLines} lines)</div>
                <hr />
                </div>
              )
            }

            <p className="control">
              <button
                className="button is-danger box-button is-outlined"
                onClick={() => {
                  dispatch({
                    type: "ATTACH_FRAME_TO_COLLECTION",
                    collectionid: collection.id,
                    hasFrame: false,
                  });
                  dispatch({ type: "SET_PROJECT_HAS_FRAME", hasFrame: false });
                }}
              >
                <span className="icon is-small">
                  <i className="fa-duotone fa-trash-alt" />
                </span>
                <span>Drop Collection Frame</span>
              </button>
            </p>
          </div>
        ) : (
          <p className="control">
            <button
              className="button is-primary box-button"
              onClick={buildCollectionFrame}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-database" />
              </span>
              <span>Build Collection Frame</span>
            </button>
          </p>
        )}
      </div>
    </>
  );
};


// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ data, hasTable }) => {
  if (!data || !hasTable || !data.length) return null;
  return (
    <div className="table-container">
      <table className="table is-fullwidth is-striped is-hoverable small-table is-bordered collection">
        <thead>
          <tr>
            {Object.keys(data[0]).map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowindex) => (
            <tr key={`row-${rowindex}`}>
              {Object.keys(row).map((key) => {
                let entry = row[key];
                if (!entry) {
                  return <td key={`${rowindex}-${key}`}></td>;
                }
                if (entry.length > 10) {
                  entry = entry.substr(0, 10) + "...";
                }
                if (entry.length) entry = entry.replace(/ /g, "\u00A0");
                return <td key={`${rowindex}-${key}`}>{entry}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
