import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// ------------------------------------------------------
import { countries } from "Data/countries";
// ------------------------------------------------------
import { calculateDerived } from "Redux/files/actions";
import { initCanonicals } from "Redux/database/actions";
// ------------------------------------------------------
import "./css.css";
import waitbar from "Media/waitbar.gif";
// ------------------------------------------------------


// -------------------------------------------------------------
import CollectionsProcessFrame from "Components/CollectionsProcessFrame";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Exported = () => {
  return (
    <CollectionsProcessFrame>
      <Index />
    </CollectionsProcessFrame>
  );
};

export default Exported;

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const nav = useSelector((state) => state.nav);

  const [isDeriving, setDeriving] = useState(false);
  const [pick, setPick] = useState(null);

  const {
    project,
    project: { parsers },
  } = projects;
  const { collectionsRepo, flatFilesReverse } = project;
  const { collectionid } = nav;

  useEffect(() => {
    if (collectionid && !sql) {
      const files = collection.fileids.map(
        (fileid) => flatFilesReverse[fileid]
      );
      dispatch(initCanonicals(files, collectionid));
    }
  }, []);

  if (!collectionid) {
    return null;
  }

  const collection = collectionsRepo[collectionid];
  const files = collection.fileids.map((fileid) => flatFilesReverse[fileid]);
  const { sql, hasEventDates, territoryCol, territory, hasTerritory } =
    collection;
  const dateparsers = collection.dateparsers || [];
  const datecols = dateparsers.map((parser) => parser.column);
  if (!sql) {
    return (
      <div className="box">
        <img src={waitbar} />
        <div>Gathering Headers</div>
      </div>
    );
  }

  const samples = {};
  sql.dbHeaders.forEach((header) => {
    samples[header.header] = header.sample;
  });

  const remainingHeaders = datecols
    ? sql.dbHeaders.filter(
        (x) =>
          ![...datecols, "Event_Start_DD", "Event_End_DD"].includes(x.header)
      )
    : sql.dbHeaders;

  const derive = () => {
    setDeriving(true);
    dispatch(
      calculateDerived(files, dateparsers, () => {
        setDeriving(false);
        dispatch({
          type: "ATTACH_EVENT_DATES",
          collectionid,
          hasDates: true,
        });
      })
    );
  };

  const onDrop = (e) => {
    const column = e.dataTransfer.getData("canonical");
    dispatch({
      type: "ASSIGN_EVENT_DATE_PARSER",
      column,
      collectionid,
    });
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const Format = ({ init, onChange }) => {
    const [value, setValue] = useState(init);
    return (
      <input
        className="input"
        value={value}
        onChange={(evt) => {
          const thisValue = evt.target.value
            .toUpperCase()
            .replace(/[^MYDQHXLS\|-]/g, "");
          setValue(thisValue);
        }}
        onKeyDown={(evt) => {
          if (evt.key === "Enter") onChange(value);
        }}
        onBlur={() => onChange(value)}
      />
    );
  };

  return (
    <div className="box">
      <div className="columns">
        <div className="column">
          <div className="table-container">
            <div className="table is-striped is-bordered">
              {remainingHeaders.map((header, headerindex) => (
                <Header
                  key={header.header}
                  header={header}
                  collectionid={collectionid}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          className="column is-full"
        >
          <div
            className="box"
            style={{ position: "fixed" }}
            onDrop={onDrop}
            onDragOver={onDragOver}
          >
            <div className="title">Event Dates</div>
            <div className="columns">
              <div className="column">
                <table className="table is-bordered">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Event Date Column</th>
                      <th>Date Format</th>
                      <th></th>
                      <th>Sample</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dateparsers.length
                      ? dateparsers.map((dateparser, parserindex) => (
                          <tr key={dateparser.column}>
                            <td>{parserindex + 1}</td>
                            <td>{dateparser.column}</td>
                            <td>
                              <Format
                                init={dateparser.format}
                                onChange={(format) => {
                                  dispatch({
                                    type: "ASSIGN_EVENT_DATE_PARSER_FORMAT",
                                    parserindex,
                                    format,
                                    collectionid,
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <button
                                className="delete"
                                onClick={() => {
                                  dispatch({
                                    type: "REMOVE_EVENT_DATE_PARSER",
                                    index: parserindex,
                                    collectionid,
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <div className="header-sample">
                                <ul>
                                  {samples[dateparser.column].map(
                                    (sample, sampleid) => (
                                      <li key={`sample-${sampleid}`}>
                                        {sample && typeof sample === "string"
                                          ? sample.replace(/ /, "\u00A0")
                                          : sample}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
                {datecols.length ? (
                  hasEventDates ? (
                    <div>
                      <div className="event-calculated">
                        Event Dates Calculated
                        <i className="fa-duotone fa-check" />
                      </div>
                      <br />
                      <button
                        className={`button ${isDeriving ? "is-loading" : ""}`}
                        onClick={derive}
                      >
                        <span className="icon is-small">
                          <i className="fa-duotone fa-calendar-alt" />
                        </span>
                        <span>Re-Calculate</span>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className={`button is-primary ${
                          isDeriving ? "is-loading" : ""
                        }`}
                        onClick={derive}
                      >
                        <span className="icon is-small">
                          <i className="fa-duotone fa-calendar-alt" />
                        </span>
                        <span>Calculate Event Dates</span>
                      </button>
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// -----------------------------------------------------
// -----------------------------------------------------
const Header = ({ header, collectionid }) => {
  const dispatch = useDispatch();
  const onDragStart = (e) => {
    e.dataTransfer.setData("canonical", header.header);
    console.log("Dragging it");
  };
  return (
    <tr className="date-header">
      <td>
        <div
          className="tag is-info is-light"
          draggable
          onDragStart={onDragStart}
        >
          {header.header.replace(/ /, "\u00A0")}
        </div>
      </td>
      <td>
        <div className="header-sample">
          <ul>
            {header.sample
              ? header.sample.map((sample, sampleid) => (
                  <li key={`sample-${sampleid}`}>
                    {sample && typeof sample === "string"
                      ? sample.replace(/ /, "\u00A0")
                      : sample}
                  </li>
                ))
              : null}
          </ul>
        </div>
      </td>
    </tr>
  );
};
