import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import Dir from "Components/Dir";
import ProgressBar from "Components/ProgressBar";
import Collection from "Components/Collection";
import FixedAdd from "./FixedAdd";
import FileViewer from "Components/FileViewer";
// -------------------------------------------------------------
import BulkReference from "./BulkReference";
// -------------------------------------------------------------
import { displayNewCollectionPopup } from "Redux/collections/actions";
import { setCollection } from "Redux/nav/actions";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects);
  const { collectionids, collectionsRepo, dirs } = projects.project;
  const { collectionid } = useSelector((state) => state.nav);

  useEffect(() => {
    if (!collectionid) {
      const collections = Object.values(collectionsRepo).filter(
        (collection) => collection.fileids.length > 0
      );
      if (collections.length > 0) {
        dispatch(
          setCollection({
            id: collections[0].id,
            headline: collections[0].headline,
          })
        );
      }
    }
  }, []);

  const { project } = projects;
  if (!project) return <div />;

  return (
    <div>
      <BulkReference />
      <ProgressBar />
      <FileViewer />
      <div className="main-files">
        <div className="columns">
          <div className="column">
            <div className="box">
              {dirs.map((dir) => (
                <Dir
                  key={dir.id}
                  dir={dir}
                  current={projects.current}
                  setFiles={() => {}} //setFiles }
                  setCurrent={() => {}} //setCurrent }
                />
              ))}
            </div>
          </div>
          <div className="column" style={{ display: "block" }}>
            <div className="box">
              <div className="field is-grouped">
                <p className="control">
                  <button
                    className="button"
                    onClick={() => {
                      dispatch(displayNewCollectionPopup(true));
                    }}
                  >
                    <span className="icon is-small">
                      <i className="fa-duotone fa-plus"></i>
                    </span>
                    <span>New Collection</span>
                  </button>
                </p>
              </div>
              <div>
                {collectionids.map((collectionid, colindex) => (
                  <Collection
                    key={`collection-${colindex}`}
                    collectionid={collectionid}
                    collectionsRepo={collectionsRepo}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FixedAdd />
    </div>
  );
};
export default Index;
